<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
      </div>
      <div class="card-toolbar">
        <el-button size="large" v-if="total > 0" class="btn btn-sm btn-dark" type="primary" data-bs-toggle="modal" data-bs-target="#order_summery_fields_modal" :loading="loading"><i class="bi bi-file-earmark-spreadsheet fs-4"></i> {{ "Export Data" }}</el-button>
        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="/media/icons/duotune/general/gen024.svg" />
          </span>
        </button>
        <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
          <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{generalConstants.FILTER_COLUMNS}}</div>
          </div>
          <div class="separator border-gray-200"></div>
          <div class="px-7 py-5">
            <div class="mb-10">
              <el-checkbox
                :disabled="visibleFields.length == 1 && header.visible"
                v-for="header in tableHeader" 
                :key="header.key" 
                v-model="header.visible" 
                inline >
                {{ header.name }}
              </el-checkbox>
            </div>
            <div class="d-flex justify-content-end">
              <button type="submit" @click="submitFilters(visibleFields)" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" > {{generalConstants.SAVE}} </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" id="order_summery_fields_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Fields Which You Want To Export</h5>
            <div class="btn btn-icon btn-sm btn-active-color-primary ms-2" id="ordersummaryclosebutton" data-bs-dismiss="modal" aria-label="Close">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <el-checkbox-group v-model="checkedOrderSummeryExportFields">
                  <el-checkbox v-for="(fields, index) in orderSummeryExportFields" :key="index" :label="index">
                    {{ fields }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-gray" @click="exportOrderSummeryData('xlsx')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ "Export With Excel" }}
            </button>
            <button type="button" class="btn btn-gray" @click="exportOrderSummeryData('csv')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ "Export With CSV" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-6">
      <div class="row">
        <div class="col-xl-12">
          <!--begin::Accordion-->
          <div class="accordion" id="kt_accordion_1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="kt_accordion_1_header_1">
                <button class="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="true" aria-controls="kt_accordion_1_body_1">
                  <h4 class="fw-bolder m-0">Filters</h4>
                </button>
              </h2>
              <div id="kt_accordion_1_body_1" class="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                <div class="accordion-body">
                  <div class="d-flex align-items-center position-relative my-1">
                    <div class="row">
                      <div class="col-md-2">
                        <input type="text" v-model="searchParams.search" v-on:keyup.enter="handleSearch()" class="form-control me-2" :placeholder="ordersConstants.SEARCH_ORDERS" />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.partner" :placeholder="generalConstants.SELECTTRADINGPARTNER" size="large">
                          <el-option value="">{{generalConstants.SELECTTRADINGPARTNER}}</el-option>
                          <el-option v-for="partner in tradingPartners" :value="partner.trading_partner_id" :key="partner" :label="partner.user.name">
                            {{partner.user.name}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order date"
                          end-placeholder="Order date"
                          size="large"
                        />
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDueDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order Due date"
                          end-placeholder="Order Due date"
                          size="large"
                          :shortcuts="shortcuts"
                          unlink-panels
                        />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.searchByOrderStatus" :placeholder="ordersConstants.ORDER_STATUS" size="large">
                          <el-option value="">{{ordersConstants.ORDER_STATUS}}</el-option>
                          <el-option v-for="status in orderStatus" :value="status.type" :key="status" :label="status.title">
                            {{status.title}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-2 mt-2">
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
                          <button type="button" @click.prevent="handleSearch()" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
                        </el-tooltip>
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
                          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Accordion-->
        </div>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-order_date="{ row: data }">
          {{ data.order_date != NULL ? formatDate(data.order_date) : '-' }}
        </template>
        <template v-slot:cell-order_id="{ row: data }">
          <template v-if="permission.isViewAllowed">
            <a href="javascript:void(0)" @click.prevent="openDetailPage(data.id)">{{ data.order_id }}</a>
          </template>
          <template v-else>
            {{ data.order_id }}
          </template>
        </template>
        <template v-slot:cell-order_type="{ row: data }">
          <span v-if="data.order_type && data.order_type == '0'">
            Manual
          </span>
          <span v-else-if="data.order_type && data.order_type == '1'">
            Online
          </span>
          <span v-else-if="data.order_type && data.order_type == '2'">
            Offline
          </span>
          <span v-else>
            -
          </span>
        </template>
        <template v-slot:cell-order_status="{ row: data }">
            {{data.orderstatus.title}}
        </template>
        <template v-slot:cell-trading_partner="{ row: data }">
          <div v-if="permission.isViewAllowed"><a href="javascript:void(0)" @click.prevent="openDetailPage(data.id)">
            <span v-if="data.trading_partner && data.trading_partner.user && data.trading_partner.user.name">{{data.trading_partner.user.name}}</span><br>
            <span v-if="data.trading_platforms && data.trading_platforms.platform_name"> ({{data.trading_platforms.platform_name}}) </span><br>
            <span v-if="(data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) || (data.trading_platforms && data.trading_platforms.platform_name)">({{data.order_id}})</span>
          </a>
          </div>
          <div v-else>
            <span v-if="data.trading_partner && data.trading_partner.user && data.trading_partner.user.name">{{data.trading_partner.user.name}}</span> <br>
            <span v-if="data.trading_platforms && data.trading_platforms.platform_name"> ({{data.trading_platforms.platform_name}}) </span><br>
            <span v-if="(data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) || (data.trading_platforms && data.trading_platforms.platform_name)">({{data.order_id}})</span>
          </div>
        </template>
        <template v-slot:cell-id="{ row: data }">
          <p class="mt-2 mb-0">{{ data.id}}</p>
        </template>
        <template v-slot:cell-shipping_method="{ row: data }">
          {{ data.order_payment.shipping_charges }}
        </template>
        <template v-slot:cell-discount="{ row: data }">
          {{ data.order_payment.discount }}
        </template>
        <template v-slot:cell-tax="{ row: data }">
          {{ data.order_payment.tax }}
        </template>
        <template v-slot:cell-platform="{ row: data }">
          {{ data.trading_platforms.platform_name }}
        </template>
        <template v-slot:cell-total_amount="{ row: data }">
          {{ formatPrice(data.order_payment.total_amount) }}
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatText,formatDate, formatPrice } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { notificationFire } from "@/composable/notification.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "orders-summary",
  components: {
    Datatable
  },
  setup() {
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const tradingPartners = ref([]);
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const checkedOrderSummeryExportFields = ref([]);
    const orderStatus = ref([]);
    const searchParams = reactive({
      recordsPerPage: Number(JSON.parse(window.localStorage.getItem('site_config'))['per_page']),
      pageNub: 1,
      page_type: "order-summary-report",
      partner:"",
      search:"",
      sortBy:"",
      sortDesc:"",
      searchByOrderDate:"",
      searchByOrderDueDate:"",
      type:"listing",
      searchByOrderStatus: "",
    })

    const orderSummeryExportFields = reactive({
      order_date: 'Order Date',
      id: 'Order ID',
      order_id: 'Partner Order ID',
      order_status: 'Order Status',
      trading_partner: 'Trading Partner Details',
      platform: 'Platform Name',
      order_type: 'Order Type',
      shipping_method: 'Shipping',
      discount: 'Discount',
      tax: 'Tax',
      total_amount: 'Total'
    });

    const openDetailPage = (order_id) => {
      router.push('/orders/'+order_id)
    }

    const submitFilters = async (filters) => {
      
      if (!tableHeader) return
        let filterData = {
          orderSummeryReportFilters: tableHeader.map(({key, visible}) => ({[key]: visible})),
        };
        ApiService.post('users/save-filters', filterData)
        .then((response) => {
          if (response.data) {
            let status = response.status;
            let message = response.data.message;
            if (status == 200 || status == 201) {
              notificationFire(message,'success');
            } else {
              notificationFire(message,'error');
            }
          }
        })
        .catch((error) => {
          loading.value = false;
          loadingback.value = false;
          let message = error.message;
          notificationFire(message,'error');
        });
    }

    //Export all ordre summery data in xlsx/csv file
    const exportOrderSummeryData = async (type) => {
      searchParams.type = "export";
      axios({
        url: 'order-summary/export-data',
        method: 'POST',
        data: {
          params: searchParams,
          extension: type,
          exportFields: checkedOrderSummeryExportFields.value
        },
        responseType: 'arraybuffer',
      }).then((response) => {
        document.getElementById('ordersummaryclosebutton').click();
        let blob = new Blob([response.data], {
            type: ''
        })
        let link = document.createElement('a')
        link.setAttribute('target','_blank')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Order_Summery_Report_'+Date.now()+'.'+ type
        link.click()
      })
      .catch((error) => {
        let response = error;
          if (response != "") {
            notificationFire('order summery data is not exported', "error");
          }
      });
    }

    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const permission = reactive({
      isViewOrderSummaryReportAllowed: false,
      isViewAllowed: false,
    })

    const tableHeader = reactive([
			{
        name: 'Order Date',
        key: 'order_date',
        width: "w-200px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'HOFMP Order ID',
        key: "id",
        sortable: true,
        width: "w-150px",
        visible: true,
        alignment: 'text-start'
      },
      {
        name: 'Trading Partner Order ID',
        key: 'order_id',
        width: "w-200px",
        visible: true,
        alignment: 'text-center'
      },
      {
        name: 'Order Type',
        key: 'order_type',
        width: "w-150px",
        visible: false,
        alignment: 'text-center',
        sortable: false,
      },
      {
        name: 'Status',
        key: 'order_status',
        width: "w-200px",
        visible: false,
        alignment: 'text-center',
        sortable: false,
      },
      {
        name: 'Trading Partner',
        key: 'trading_partner',
        width: "w-300px",
        visible: false,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Platform Name',
        key: 'platform',
        width: "w-250px",
        visible: false,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Shipping',
        key: 'shipping_method',
        width: "w-250px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Discount',
        key: 'discount',
        width: "w-100px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Tax',
        key: 'tax',
        width: "w-100px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Total',
        key: 'total_amount',
        width: "w-300px",
        visible: true,
        alignment: 'text-center',
        sortable: false,
      },
    ]);

    const visibleFields = computed(() => {
      return tableHeader.filter(header => header.visible)
    })
    
    const handleSizeChange = (number) => {
      searchParams.recordsPerPage = number;
      handleCurrentChange(1);
    }

    const handleSortChange = (obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      getOrderSummerReportData();
    }
    
    const handleCurrentChange = (number) => {
      searchParams.pageNub = number;
      getOrderSummerReportData();
    }

    //Get all active statuses
    const getActiveStatusForOrders = async () => {
      loading.value = true;
      await ApiService.query("get-order-status")
      .then(({ data }) => {
        if (data.data) {
          orderStatus.value = data.data;
        } else {
          orderStatus.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        orderStatus.value = [];
        loading.value = false;
      });
    }

    //Get all orders summary report data
    const getOrderSummerReportData = async () => {
      loading.value = true;
      searchParams.type = "listing";
      await ApiService.post("reports/order-summary", { params: searchParams })
      .then(({ data }) => {
        if(data.data.filter)
        {
          var filterData = data.data.filter;
          tableHeader.forEach((options, headerKey) => {
            let key = options.key;
            if (filterData[key] !== undefined) {
              tableHeader[headerKey].visible = filterData[key];
              if(filterData[key] == true){
                checkedOrderSummeryExportFields.value.push(tableHeader[headerKey].key);
              }
            }
          });
        }
        if (data.data.data.length) {
          tableData.value.splice(0, tableData.value.length, ... data.data.data);
          total.value = data.data.total;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
        }
        else
        {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
        setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tableData.value = [];
        total.value = 0;
        loading.value = false;
      });
    }

    //Get all active trading partners
    const getTradingPartners = async () => {
      loading.value = true;
      await ApiService.query("get-active-trading-partner")
      .then(({ data }) => {
        if (data.data) {
          tradingPartners.value = data.data;
        } else {
          tradingPartners.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tradingPartners.value = [];
        loading.value = false;
      });
    }

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
      }
      searchParams.partner = "";
      searchParams.searchByOrderDate = "";
      searchParams.searchByOrderDueDate = "";
      searchParams.searchByOrderStatus = "";
      getOrderSummerReportData();
    };

    //Order search event function
    const handleSearch = () => {
      handleCurrentChange(1);
    }

    //Set breadcrumbs and get the orders summary data for report
    onMounted(async () => {
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("ViewOrderSummaryReport") || role == "Super Admin")
        permission.isViewOrderSummaryReportAllowed = true;
      if (per.includes("viewOrder") || role == "Super Admin")
        permission.isViewAllowed = true;
      getTradingPartners();
      getActiveStatusForOrders();
      await getOrderSummerReportData();
      setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    })
    
    return {
      tableData,
      getOrderSummerReportData,
      total,
      handleSizeChange,
      handleCurrentChange,
      handleSortChange,
      loading,
      searchParams,
      tradingPartners,
      tableHeader,
      generalConstants,
      noDataFound,
      updateProps,
      resetSearch,
      ordersConstants,
      handleSearch,
      shortcuts,
      permission,
      formatDate,
      formatPrice,
      visibleFields,
      checkedOrderSummeryExportFields,
      orderSummeryExportFields,
      exportOrderSummeryData,
      submitFilters,
      orderStatus,
      openDetailPage
    }
  },
};

</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.el-link {
  justify-content: left;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
// For Export Button
.btn.btn-gray{
  background-color: #858585;
  border-color: #858585;
  color:#ffffff;
}
.btn.btn-gray i{color:#ffffff;}
.btn.btn-gray svg path{fill:#ffffff !important;}
.btn.btn-gray:hover,.btn.btn-gray:focus,.btn.btn-gray:active{
  background-color: #666666;
  border-color: #666666;
}
</style>